import './myTextArea.css'

const MyTextArea = (props) => {


  return(
    <textarea className="myTextArea" name="" id="" cols="30" rows="10" {...props}></textarea>
  )
}

export default MyTextArea